import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AskSohpieButton from 'ai/AskSohpieButton';
import type { PromptMessage } from 'ai/AskSohpieButton/AskSohpieButton.type';
import { useStrategy } from 'strategy/StrategyProvider';

import { useStrategyObjectives } from './StrategyObjectivesProvider';

const StrategyObjectivesAskSophieButton = () => {
  const { t } = useTranslation();

  const { strategy } = useStrategy();
  const { objectives } = useStrategyObjectives();

  const objectiveIds = objectives
    .filter((objective) => objective.isFiltersMatch)
    .map((objective) => objective.id);

  const promptMessages = useMemo<PromptMessage[]>(
    () => [
      {
        content: t('ai.strategySummary.button.objectiveAlignment'),
        mainPrompt:
          'Please analyze if our objectives are aligned. Focus on alignment, not on the status of each element.',
        numberOfCharacters: 5000,
        temperature: 1,
        hints: [
          'Analyze alignment of the given objectives.',
          'In your response focus on alignment not on the status of each objective.',
          'Group the results first per theme, then within each theme group by a) Seems to be well aligned, b) potentially misaligned.',
        ],
      },
    ],
    [t],
  );

  return (
    <AskSohpieButton
      promptMessages={promptMessages}
      strategyId={strategy?.id}
      objectiveIds={objectiveIds}
      infoText={t('ai.strategySummary.start.subHeader.strategyObjectives')}
    />
  );
};

export default StrategyObjectivesAskSophieButton;
