import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useStrategy } from 'strategy/StrategyProvider';
import type { PromptMessage } from 'ai/AskSohpieButton/AskSohpieButton.type';
import AskSohpieButton from 'ai/AskSohpieButton';

import { useStrategyOverviewAskSophieButtonQuery } from './StrategyOverviewAskSophieButton.graphql.generated';

const StrategyOverviewAskSophieButton = () => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const { strategy } = useStrategy();

  const handleApolloError = useHandleApolloError();

  const { data } = useStrategyOverviewAskSophieButtonQuery({
    onError: handleApolloError,
    variables: {
      filter: {
        orgUnitIds: teamAdapter.isOrgUnit
          ? {
              operator: 'IN',
              values: [teamAdapter.keyArg],
            }
          : { operator: 'IS_NULL' },
        status: {
          isComplete: false,
        },
      },
    },
    skip: !strategy,
  });

  const objectiveIds = data?.allObjectives.edges.map((edge) => edge.node.id);

  const promptMessages = useMemo<PromptMessage[]>(
    () => [
      {
        content: t('ai.strategySummary.button.story'),
        mainPrompt: t('ai.strategySummary.button.story'),
        numberOfCharacters: 2000,
        temperature: 1,
        hints: [],
      },
      {
        content: t('ai.strategySummary.button.fairytale'),
        mainPrompt: 'Tell my strategy as a fairy tale story.',
        numberOfCharacters: 3000,
        temperature: 1,
      },
      {
        content: t('ai.strategySummary.button.stressTest'),
        mainPrompt: 'Please do a stress test if our strategy.',
        maxNumberOfCharacters: 5000,
        temperature: 1,
        hints: [
          'Use the following frameworks to analyse our strategy information (info in roadmap). Playing to win, Strategy Maps, Strategy Diamond, Blue Ocean Strategy, McKinsey 7S framework, The Ansoff Matrix, VRIO Framework, BCG Growth Share Matrix. For each of the frameworks, group the results in a) well covered, b) potential gap',
        ],
      },
      {
        content: t('ai.strategySummary.button.marketTrends'),
        mainPrompt:
          'Please monitor external trends impacting your strategy, including monitoring competitors movements.',
        maxNumberOfCharacters: 5000,
        temperature: 1,
        hints: [
          'Use the following frameworks to analyse potential trends and market movements impacting our strategy: PESTEL analysis, Porter’s Five Forces, SWOT analysis. For each of the frameworks list top 5 trends or movements that impacts each element in the framework.',
        ],
      },
    ],
    [t],
  );

  return (
    <AskSohpieButton
      promptMessages={promptMessages}
      strategyId={strategy?.id}
      objectiveIds={objectiveIds}
      infoText={t('ai.strategySummary.start.subHeader.overview')}
    />
  );
};

export default StrategyOverviewAskSophieButton;
