import styled from '@emotion/styled';

import { useAskSophie } from '../AskSophieProvider';
import AddSummaryButton from './PromptButton';
import type { Message } from './AskSophieSidePanel';
import type { PromptMessage } from '../AskSohpieButton.type';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

type Props = {
  addMessage: (message: Message) => void;
  isLoading: boolean;
  messages: PromptMessage[];
  scrollToBottom: (behavior: ScrollBehavior) => void;
};

const Prompt = ({ messages, isLoading, addMessage, scrollToBottom }: Props) => {
  const { objectiveIds, metricIds, initiativeIds, actionIds, strategyId } =
    useAskSophie();

  const createAddSummaryHandler = (message: PromptMessage) => () => {
    addMessage({ type: 'InputMessage', content: message.content });
    addMessage({
      type: 'SummaryMessage',
      objectiveIds,
      metricIds,
      initiativeIds,
      actionIds,
      strategyId,
      mainPrompt: message.mainPrompt,
      hints: message.hints,
      numberOfCharacters: message.numberOfCharacters,
      temperature: message.temperature,
    });
    setTimeout(() => {
      scrollToBottom('smooth');
    }, 250);
  };

  return (
    <Container>
      {messages.map((message) => (
        <AddSummaryButton
          key={message.content}
          onClick={createAddSummaryHandler(message)}
          isDisabled={isLoading}
        >
          {message.content}
        </AddSummaryButton>
      ))}
    </Container>
  );
};

export default Prompt;
