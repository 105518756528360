import compact from 'lodash/compact';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { ReactComponent as IconEdit } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as IconCheck } from 'shared/static/icons/icon-check.svg';
import { ReactComponent as IconBlock } from 'shared/static/icons/icon-block.svg';
import type { ContextMenuOnActionFn } from 'shared/components/ContextMenu';
import ContextMenu from 'shared/components/ContextMenu';
import type { OrgUnit, User } from 'types.graphql.generated';
import { useUser } from 'user/UserProvider';
import useCanPerformOrgAction from 'user/ability/useCanPerformOrgAction';
import useCanPerformOrgUnitAction from 'user/ability/useCanPerformOrgUnitAction';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import useDialogState from 'shared/hooks/useDialogState';
import EditUserProfileModal from 'user/EditUserProfileModal';
import DeactivateUserDialog from 'user/DeactivateUserDialog';
import ActivateUserDialog from 'user/ActivateUserDialog';
import RemoveOrgUnitMemberConfirmationModal from 'orgUnit/RemoveOrgUnitMemberConfirmationModal';
import type { OrgUnitWithAuthorizedActions } from 'user/ability/canPerformOrgUnitAction';

import type {
  TeamMembersListViewContextMenuItem,
  TeamMembersListViewContextMenuItemId,
} from './UserContextMenu.type';

type Props = {
  showRemove: boolean;
  user: Pick<User, 'id' | 'isActive'> & {
    orgUnit?: Pick<OrgUnit, 'id'> & OrgUnitWithAuthorizedActions;
  };
};

const UserContextMenu = ({ user, showRemove }: Props) => {
  const { t } = useTranslation();
  const { user: currentUser } = useUser();
  const { orgUnit } = useOrgUnit();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDialogState();

  const {
    isOpen: isDeactivateOpen,
    onOpen: onDeactivateOpen,
    onClose: onDeactivateClose,
  } = useDialogState();

  const {
    isOpen: isActivateOpen,
    onOpen: onActivateOpen,
    onClose: onActivateClose,
  } = useDialogState();

  const {
    isOpen: isRemoveOpen,
    onOpen: onRemoveOpen,
    onClose: onRemoveClose,
  } = useDialogState();

  const isOrgUnit = !!orgUnit;

  const canManageOrg = useCanPerformOrgAction('WRITE_ACCOUNT');
  const isCurrentUser = user.id === currentUser.id;
  const canManageUser = canManageOrg || isCurrentUser;
  const canManageOrgUnit = useCanPerformOrgUnitAction('WRITE');

  const items = useMemo<TeamMembersListViewContextMenuItem[]>(
    () =>
      compact([
        canManageUser && {
          icon: IconEdit,
          id: 'editProfile',
          title: t('userMenu.editProfile'),
        },
        canManageUser &&
          !isCurrentUser &&
          user.isActive &&
          !isOrgUnit && {
            icon: IconBlock,
            id: 'deactivate',
            title: t('userMenu.deactivate.menuItem'),
          },
        canManageUser &&
          !user.isActive && {
            icon: IconCheck,
            id: 'activate',
            title: t('userMenu.activate.menuItem'),
          },
        canManageOrgUnit &&
          isOrgUnit &&
          showRemove && {
            id: 'remove',
            title: t('remove'),
            variant: 'danger',
            icon: TrashIcon,
          },
      ]),
    [
      canManageOrgUnit,
      canManageUser,
      isCurrentUser,
      isOrgUnit,
      showRemove,
      t,
      user.isActive,
    ],
  );

  const onAction = useCallback<
    ContextMenuOnActionFn<TeamMembersListViewContextMenuItemId>
  >(
    (actionId: TeamMembersListViewContextMenuItemId) => {
      switch (actionId) {
        case 'editProfile':
          onEditOpen();
          break;
        case 'deactivate':
          onDeactivateOpen();
          break;
        case 'activate':
          onActivateOpen();
          break;
        case 'remove':
          onRemoveOpen();
          break;
      }
    },
    [onActivateOpen, onDeactivateOpen, onEditOpen, onRemoveOpen],
  );

  return (
    <>
      <ContextMenu<TeamMembersListViewContextMenuItem>
        onAction={onAction}
        items={items}
      />

      <EditUserProfileModal
        isOpen={isEditOpen}
        userId={user.id}
        onClose={onEditClose}
      />

      <DeactivateUserDialog
        userId={user.id}
        onClose={onDeactivateClose}
        isOpen={isDeactivateOpen}
      />

      <ActivateUserDialog
        userId={user.id}
        onClose={onActivateClose}
        isOpen={isActivateOpen}
      />

      {user.orgUnit && (
        <RemoveOrgUnitMemberConfirmationModal
          isOpen={isRemoveOpen}
          onClose={onRemoveClose}
          orgUnitId={user.orgUnit.id}
          orgUnit={user.orgUnit}
          userId={user.id}
        />
      )}
    </>
  );
};

export default UserContextMenu;
