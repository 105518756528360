import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import type { SerializedStyles } from '@emotion/react';

import { useAddInitiativeStatusMutation } from 'initiative/CreateInitiativeStatusDialog/AddInitiativeStatus.graphql.generated';
import ContextMenu from 'shared/components/ContextMenu';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import useDialogState from 'shared/hooks/useDialogState';
import { useToasts } from 'shared/toast/useToasts';
import type { Initiative, Objective } from 'types.graphql.generated';
import {
  canPerformStrategyElementAction,
  type StrategyElementWithAuthorizedActions,
} from 'user/ability/canPerformStrategyElementAction';
import { useDeleteInitiativesMutation } from 'initiative/initiative.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import CreateInitiativeStatusDialog from 'initiative/CreateInitiativeStatusDialog';
import EditInitiative from 'initiative/EditInitiative';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as CheckmarkDoneIcon } from 'shared/static/icons/icon-checkmark-done.svg';
import { ReactComponent as RefreshIcon } from 'shared/static/icons/icon-refresh.svg';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import SendStatusRequestsDialog from 'status/SendStatusRequestsDialog';

import { useUnlinkInitiativeMutation } from './InitiativeContextMenu.graphql.generated';

type Props = {
  contextObjective?: Pick<Objective, 'id'> &
    StrategyElementWithAuthorizedActions;
  initiative: Pick<Initiative, '__typename' | 'id' | 'isCurrentUserOwner'> & {
    currentInitiativeStatus?: { complete: boolean };
    objective?: Pick<Objective, 'id'>;
  } & StrategyElementWithAuthorizedActions;
  onDelete?: () => void;
  triggerStyle?: SerializedStyles;
};

const InitiativeContextMenu = ({
  initiative,
  onDelete,
  contextObjective,
  triggerStyle,
}: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { activeOrg } = useActiveOrg();

  const initiativeId = initiative.id;

  const [
    isDeleteConfirmationModalOpened,
    toggleIsDeleteConfirmationModalOpened,
  ] = useToggle(false);

  const [
    isCompleteConfirmationModalOpened,
    toggleIsCompleteConfirmationModalOpened,
  ] = useToggle(false);

  const {
    isOpen: isUnlinkOpen,
    onOpen: onUnlinkOpen,
    onClose: onUnlinkClose,
  } = useDialogState();

  const {
    isOpen: isCreateStatusOpen,
    onOpen: onCreateStatusOpen,
    onClose: onCreateStatusClose,
  } = useDialogState();

  const {
    isOpen: isCompleteStatusWizardOpen,
    onOpen: onOpenCompleteStatusWizard,
    onClose: onCloseCompleteStatusWizard,
  } = useDialogState();

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDialogState();

  const {
    isOpen: isSendStatusRequestOpen,
    onOpen: onSendStatusRequestOpen,
    onClose: onSendStatusRequestClose,
  } = useDialogState();

  const onError = useHandleApolloError();

  const [createCompletedInitiativeStatus] = useAddInitiativeStatusMutation({
    variables: {
      input: {
        orgKey: activeOrg.orgKey,
        initiativeId,
        complete: true,
      },
    },
    onCompleted: () =>
      addToast({
        id: 'completeSuccessToast',
        variant: 'success',
        children: t('strategy.toasts.completeSuccessToast'),
      }),
    onError,
  });

  const [deleteInitiative] = useDeleteInitiativesMutation({
    variables: { initiativeId },
    update(cache) {
      setTimeout(() => cache.evict({ id: `Initiative:${initiativeId}` }), 0);
    },
    onCompleted: () =>
      addToast({
        id: 'removeInitiativeSuccessToast',
        variant: 'success',
        children: t('strategy.toasts.removeSuccessToast'),
      }),
    onError,
  });

  const [createActiveInitiativeStatus] = useAddInitiativeStatusMutation({
    variables: {
      input: {
        orgKey: activeOrg.orgKey,
        initiativeId,
        complete: false,
      },
    },
    onCompleted: () =>
      addToast({
        id: 'reactivateInitiativeSuccessToast',
        variant: 'success',
        children: t('strategy.toasts.reactivateSuccessToast'),
      }),
    onError,
  });

  const [unlinkInitiative] = useUnlinkInitiativeMutation({
    variables: {
      initiativeId: initiative.id,
      objectiveId: contextObjective?.id || '',
    },
    onCompleted: () =>
      addToast({
        id: 'unlinkInitiativeSuccessToast',
        variant: 'success',
        children: t('initiative.contextMenu.toast.unlink'),
      }),
    onError,
  });

  const handleDeleteInitiative = useCallback(
    () => deleteInitiative().then(onDelete),
    [deleteInitiative, onDelete],
  );

  const items = useMemo(() => {
    if (!initiative) return [];

    const canRequestStatus = !initiative.isCurrentUserOwner;
    const canWrite = canPerformStrategyElementAction(initiative, 'WRITE');
    const canReactivate =
      initiative?.currentInitiativeStatus?.complete && canWrite;
    const canComplete =
      !initiative?.currentInitiativeStatus?.complete && canWrite;
    const isLink =
      contextObjective && initiative.objective?.id !== contextObjective.id;
    const canDelete = !isLink && canWrite;
    const canUnlink =
      isLink && canPerformStrategyElementAction(contextObjective, 'WRITE');

    return [
      canRequestStatus && {
        id: 'requestStatus',
        title: t('statusRequest.action'),
        icon: SendIcon,
      },
      canWrite && {
        id: 'shareProgress',
        title: t('initiative.contextMenu.item.shareProgress'),
        icon: PlusIcon,
      },
      canReactivate && {
        id: 'reactivate',
        title: t('initiative.contextMenu.item.reactivate'),
        icon: RefreshIcon,
      },
      canComplete && {
        id: 'complete',
        title: t('initiative.contextMenu.item.complete'),
        icon: CheckmarkDoneIcon,
      },
      canWrite && {
        id: 'edit',
        title: t('initiative.contextMenu.item.edit'),
        icon: EditIcon,
      },
      canDelete && {
        id: 'delete',
        title: t('initiative.contextMenu.item.delete'),
        icon: TrashIcon,
      },
      canUnlink && {
        id: 'unlink',
        title: t('initiative.contextMenu.item.unlink'),
        icon: TrashIcon,
      },
    ].filter(Boolean);
  }, [contextObjective, initiative, t]);

  const handleAction = useCallback(
    (itemId: string) => {
      switch (itemId) {
        case 'requestStatus':
          onSendStatusRequestOpen();
          return;
        case 'shareProgress':
          onCreateStatusOpen();
          return;
        case 'reactivate':
          createActiveInitiativeStatus();
          return;
        case 'complete':
          toggleIsCompleteConfirmationModalOpened();
          return;
        case 'edit':
          onEditOpen();
          return;
        case 'delete':
          toggleIsDeleteConfirmationModalOpened();
          return;
        case 'unlink':
          onUnlinkOpen();
      }
    },
    [
      createActiveInitiativeStatus,
      onCreateStatusOpen,
      onEditOpen,
      onSendStatusRequestOpen,
      onUnlinkOpen,
      toggleIsCompleteConfirmationModalOpened,
      toggleIsDeleteConfirmationModalOpened,
    ],
  );

  return (
    <>
      <ContextMenu
        items={items}
        onAction={handleAction}
        testId={'initiative'}
        triggerStyle={triggerStyle}
      />

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpened}
        onClose={toggleIsDeleteConfirmationModalOpened}
        onConfirm={handleDeleteInitiative}
      >
        {t('initiative.contextMenu.confirmation.delete')}
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isCompleteConfirmationModalOpened}
        onClose={toggleIsCompleteConfirmationModalOpened}
        onConfirm={onOpenCompleteStatusWizard}
        onDeny={createCompletedInitiativeStatus}
      >
        {t('initiative.contextMenu.confirmation.complete')}
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={isUnlinkOpen}
        onClose={onUnlinkClose}
        onConfirm={unlinkInitiative}
      >
        {t('initiative.contextMenu.confirmation.unlink')}
      </ConfirmationModal>

      {initiative && (
        <CreateInitiativeStatusDialog
          isOpen={isCreateStatusOpen}
          initiativeId={initiative.id}
          onClose={onCreateStatusClose}
        />
      )}

      {initiative && (
        <CreateInitiativeStatusDialog
          initiativeId={initiative.id}
          isOpen={isCompleteStatusWizardOpen}
          onClose={onCloseCompleteStatusWizard}
          completeStatus={true}
        />
      )}

      <EditInitiative
        initiativeId={initiative.id}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />

      <SendStatusRequestsDialog
        isOpen={isSendStatusRequestOpen}
        onClose={onSendStatusRequestClose}
        strategyElements={[initiative]}
      />
    </>
  );
};

export default InitiativeContextMenu;
