import type { TFunction } from 'react-i18next';

import { yup } from 'shared/services/yup.service';

export const createValidationSchema = (t: TFunction) =>
  yup.object({
    name: yup.string().required(),
    nameAbbreviated: yup
      .string()
      .test(
        'is1to3letters',
        t('orgUnit.orgUnitForm.nameAbbreviated.error'),
        (value) => yup.string().required().min(1).max(3).isValidSync(value),
      ),
    description: yup.string(),
    lead: yup.string().email().required(),
    admins: yup
      .array()
      .of(yup.string().required(t('multiUserInput.userNotAddedValidation')))
      .required(),
  });
