import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';
import type { SerializedStyles } from '@emotion/react';

import ContextMenu from 'shared/components/ContextMenu';
import { ObjectiveDocument } from 'objective/ObjectiveProvider';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as CheckmarkDoneIcon } from 'shared/static/icons/icon-checkmark-done.svg';
import { ReactComponent as RefreshIcon } from 'shared/static/icons/icon-refresh.svg';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useAddObjectiveStatusMutation } from 'objective/CreateObjectiveStatusDialog/AddObjectiveStatus.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import ConfirmationModal from 'shared/components/__DEPRECATED__/ConfirmationModal';
import CreateObjectiveStatusDialog from 'objective/CreateObjectiveStatusDialog';
import useDialogState from 'shared/hooks/useDialogState';
import type { Objective, ObjectiveStatus } from 'types.graphql.generated';
import EditObjective from 'objective/EditObjective';
import SendStatusRequestsDialog from 'status/SendStatusRequestsDialog';

import type {
  ObjectiveContextMenuItem,
  ObjectiveContextMenuItemId,
} from './ObjectiveContextMenu.type';
import DeleteObjectiveConfirmationModal from './DeleteObjectiveConfirmationModal';

export type Props = {
  objective: Pick<Objective, '__typename' | 'id' | 'isCurrentUserOwner'> & {
    currentObjectiveStatus?: Pick<ObjectiveStatus, 'complete'>;
  } & StrategyElementWithAuthorizedActions;
  onDelete?: () => void;
  triggerStyle?: SerializedStyles;
};

const ObjectiveContextMenu = ({ objective, onDelete, triggerStyle }: Props) => {
  const { t } = useTranslation();

  const { teamAdapter } = useTeamAdapter();
  const { addToast } = useToasts();

  const {
    isOpen: isShareStatusOpen,
    onOpen: onShareStatusOpen,
    onClose: onShareStatusClose,
  } = useDialogState();

  const {
    isOpen: isCompleteStatusWizardOpen,
    onOpen: onOpenCompleteStatusWizard,
    onClose: onCloseCompleteStatusWizard,
  } = useDialogState();

  const [
    isDeleteConfirmationModalOpened,
    toggleIsDeleteConfirmationModalOpened,
  ] = useToggle(false);

  const [
    isCompleteConfirmationModalOpened,
    toggleIsCompleteConfirmationModalOpened,
  ] = useToggle(false);

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDialogState();

  const {
    isOpen: isSendStatusRequestOpen,
    onOpen: onSendStatusRequestOpen,
    onClose: onSendStatusRequestClose,
  } = useDialogState();

  const onError = useHandleApolloError();

  const [createActiveObjectiveStatus] = useAddObjectiveStatusMutation({
    variables: {
      input: {
        orgKey: teamAdapter.orgKey,
        objectiveId: objective.id,
        complete: false,
      },
    },
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('strategy.toasts.reactivateSuccessToast'),
      }),
    onError,
    refetchQueries: [
      { query: ObjectiveDocument, variables: { objectiveId: objective.id } },
    ],
  });

  const [createCompletedObjectiveStatus] = useAddObjectiveStatusMutation({
    variables: {
      input: {
        orgKey: teamAdapter.orgKey,
        objectiveId: objective.id,
        complete: true,
      },
    },
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('strategy.toasts.completeSuccessToast'),
      }),
    onError,
    refetchQueries: [
      { query: ObjectiveDocument, variables: { objectiveId: objective.id } },
    ],
  });

  const canRequestStatus = !objective.isCurrentUserOwner;
  const canWrite = canPerformStrategyElementAction(objective, 'WRITE');
  const canReactivate = canWrite && objective.currentObjectiveStatus?.complete;
  const canComplete = canWrite && !objective.currentObjectiveStatus?.complete;

  const items = useMemo(
    (): ObjectiveContextMenuItem[] =>
      [
        canWrite && {
          id: 'shareStatus' as ObjectiveContextMenuItemId,
          title: t('objective.insights.actions.addButton'),
          icon: PlusIcon,
        },
        canRequestStatus && {
          id: 'requestStatus' as ObjectiveContextMenuItemId,
          title: t('statusRequest.action'),
          icon: SendIcon,
        },
        canReactivate && {
          id: 'reactivate' as ObjectiveContextMenuItemId,
          title: t('objective.objectiveLayout.header.menu.reactivateButton'),
          icon: RefreshIcon,
        },
        canComplete && {
          id: 'complete' as ObjectiveContextMenuItemId,
          title: t('objective.objectiveLayout.header.menu.completeButton'),
          icon: CheckmarkDoneIcon,
        },
        canWrite && {
          id: 'edit' as ObjectiveContextMenuItemId,
          title: t('objective.objectiveLayout.header.menu.editButton'),
          icon: EditIcon,
        },
        canWrite && {
          id: 'delete' as ObjectiveContextMenuItemId,
          title: t('objective.objectiveLayout.header.menu.removeButton'),
          icon: TrashIcon,
        },
      ].filter(Boolean),
    [canComplete, canReactivate, canRequestStatus, canWrite, t],
  );

  const onAction = useCallback(
    (id: ObjectiveContextMenuItemId) => {
      switch (id) {
        case 'shareStatus': {
          onShareStatusOpen();
          break;
        }
        case 'requestStatus': {
          onSendStatusRequestOpen();
          break;
        }
        case 'reactivate': {
          createActiveObjectiveStatus();
          break;
        }
        case 'complete': {
          toggleIsCompleteConfirmationModalOpened();
          break;
        }
        case 'edit': {
          onEditOpen();
          break;
        }
        case 'delete': {
          toggleIsDeleteConfirmationModalOpened();
          break;
        }
      }
    },
    [
      createActiveObjectiveStatus,
      onEditOpen,
      onSendStatusRequestOpen,
      onShareStatusOpen,
      toggleIsCompleteConfirmationModalOpened,
      toggleIsDeleteConfirmationModalOpened,
    ],
  );

  return (
    <>
      <ContextMenu<ObjectiveContextMenuItem>
        items={items}
        onAction={onAction}
        testId={'objective'}
        triggerStyle={triggerStyle}
      />

      <DeleteObjectiveConfirmationModal
        objectiveId={objective.id}
        onDelete={onDelete}
        isOpen={isDeleteConfirmationModalOpened}
        onClose={toggleIsDeleteConfirmationModalOpened}
      />

      <ConfirmationModal
        isOpen={isCompleteConfirmationModalOpened}
        onClose={toggleIsCompleteConfirmationModalOpened}
        onConfirm={onOpenCompleteStatusWizard}
        onDeny={createCompletedObjectiveStatus}
      >
        {t('objective.objectiveLayout.completeConfirmation')}
      </ConfirmationModal>

      <CreateObjectiveStatusDialog
        objective={objective}
        isOpen={isShareStatusOpen}
        onClose={onShareStatusClose}
      />

      <CreateObjectiveStatusDialog
        objective={objective}
        isOpen={isCompleteStatusWizardOpen}
        onClose={onCloseCompleteStatusWizard}
        completeStatus={true}
      />

      <EditObjective
        objectiveId={objective.id}
        isOpen={isEditOpen}
        onClose={onEditClose}
      />

      <SendStatusRequestsDialog
        isOpen={isSendStatusRequestOpen}
        onClose={onSendStatusRequestClose}
        strategyElements={[objective]}
      />
    </>
  );
};

export default ObjectiveContextMenu;
