import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { ActionPartsFragmentDoc } from '../actions.fragment.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditActionQueryVariables = Types.Exact<{
  actionId: Types.Scalars['ID'];
}>;


export type EditActionQuery = { __typename: 'Query', action: { __typename: 'Action', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, objective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentStatus?: { __typename: 'ActionStatus', id: string, completionRate?: number | undefined, complete: boolean, statusDateTime: any, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined } } | undefined, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined }, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined } } };

export type UpdateActionMutationVariables = Types.Exact<{
  input: Types.ActionInput;
}>;


export type UpdateActionMutation = { __typename: 'Mutation', updateAction: { __typename: 'Action', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, objective?: { __typename: 'Objective', id: string, name?: string | undefined } | undefined, priority?: { __typename: 'Priority', number: number, label: string } | undefined, orgUnit?: { __typename: 'OrgUnit', id: string, name?: string | undefined } | undefined, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined, currentStatus?: { __typename: 'ActionStatus', id: string, completionRate?: number | undefined, complete: boolean, statusDateTime: any, statusIndicator: StatusIndicatorClass, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined } } | undefined, auditRecord: { __typename: 'AuditRecord', updateDateTime?: any | undefined, createDateTime?: any | undefined, updateBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined, photoUrl?: string | undefined } | undefined }, timeLine: { __typename: 'TimeLine', startDate?: any | undefined, endDate?: any | undefined } } };


export const EditActionDocument = gql`
    query EditAction($actionId: ID!) {
  action(id: $actionId) {
    ...actionParts
  }
}
    ${ActionPartsFragmentDoc}`;

/**
 * __useEditActionQuery__
 *
 * To run a query within a React component, call `useEditActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditActionQuery({
 *   variables: {
 *      actionId: // value for 'actionId'
 *   },
 * });
 */
export function useEditActionQuery(baseOptions: Apollo.QueryHookOptions<EditActionQuery, EditActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditActionQuery, EditActionQueryVariables>(EditActionDocument, options);
      }
export function useEditActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditActionQuery, EditActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditActionQuery, EditActionQueryVariables>(EditActionDocument, options);
        }
export type EditActionQueryHookResult = ReturnType<typeof useEditActionQuery>;
export type EditActionLazyQueryHookResult = ReturnType<typeof useEditActionLazyQuery>;
export type EditActionQueryResult = Apollo.QueryResult<EditActionQuery, EditActionQueryVariables>;
export const UpdateActionDocument = gql`
    mutation UpdateAction($input: ActionInput!) {
  updateAction(input: $input) {
    ...actionParts
  }
}
    ${ActionPartsFragmentDoc}`;
export type UpdateActionMutationFn = Apollo.MutationFunction<UpdateActionMutation, UpdateActionMutationVariables>;

/**
 * __useUpdateActionMutation__
 *
 * To run a mutation, you first call `useUpdateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionMutation, { data, loading, error }] = useUpdateActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActionMutation, UpdateActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActionMutation, UpdateActionMutationVariables>(UpdateActionDocument, options);
      }
export type UpdateActionMutationHookResult = ReturnType<typeof useUpdateActionMutation>;
export type UpdateActionMutationResult = Apollo.MutationResult<UpdateActionMutation>;
export type UpdateActionMutationOptions = Apollo.BaseMutationOptions<UpdateActionMutation, UpdateActionMutationVariables>;