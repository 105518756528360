import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'formik';
import type { ChangeEvent } from 'react';
import { useMemo, useRef } from 'react';
import cn from 'classnames';
import { useToggle } from 'react-use';
import { useParams } from 'react-router-dom';

import {
  StrategyActionsOrgDocument,
  StrategyActionsOrgUnitDocument,
} from 'actions/StrategyActions/StrategyActionsProvider.graphql.generated';
import { ReactComponent as CheckmarkDoneIcon } from 'shared/static/icons/icon-checkmark-done.svg';
import { ReactComponent as TrashIcon } from 'shared/static/icons/icon-trash.svg';
import Menu from 'shared/components/__DEPRECATED__/Menu';
import { yup } from 'shared/services/yup.service';
import Form from 'shared/form/Form';
import Space from 'shared/components/Space';
import FieldBox from 'shared/form/FieldBox';
import { TextInputField } from 'shared/components/TextInput';
import { useToasts } from 'shared/toast/useToasts';
import Button from 'shared/components/Button';
import { SliderField2 } from 'shared/components/Slider/V2';
import { date } from 'shared/services/date.service';
import { shortenEmail } from 'shared/utils/string.utils';
import { ObjectiveActionsDocument } from 'actions/ObjectiveActions/ObjectiveActionsProvider/ObjectiveActionsProvider.graphql.generated';
import Divider from 'shared/components/Divider';
import { useTeamAdapter } from 'team/TeamAdapter';
import type { ActionFragment } from 'actions/ActionProvider/ActionProvider.graphql.generated';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import type {
  ActionStatus,
  AuditRecord,
  Objective,
} from 'types.graphql.generated';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { useDeleteActionMutation } from 'actions/ActionContextMenu/DeleteAction.graphql.generated';
import {
  useAddActionStatusMutation,
  type AddActionStatusMutation,
} from 'actions/ActionContextMenu/AddActionStatus.graphql.generated';

import styles from './ActionStatusModal.module.scss';

export type ActionStatusFormValues = {
  completionRate: number;
};

type ActionStatusFormProps = {
  action: Pick<
    ActionFragment,
    '__typename' | 'id' | 'isCurrentUserOwner' | 'currentUserAuthorizedActions'
  > & {
    auditRecord: Pick<AuditRecord, 'createDateTime'> & {
      createBy?: { displayName?: string; email?: string };
    };
    currentStatus?: Pick<ActionStatus, 'completionRate' | 'statusDateTime'>;
    objective?: Pick<Objective, 'id'>;
  };
  onClose: () => void;
  onEditOpen: () => void;
  onSendStatusRequest: () => void;
};

const id = 'ActionStatusFormId';

const ActionStatusForm = ({
  action,
  onEditOpen,
  onSendStatusRequest,
  onClose,
}: ActionStatusFormProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onError = useHandleApolloError();

  const { objectiveId } = useParams() as { objectiveId: string };

  const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);

  const { actionCreator, shortenedActionCreator } = useMemo(
    () => ({
      actionCreator:
        action?.auditRecord.createBy?.displayName ??
        action?.auditRecord.createBy?.email,
      shortenedActionCreator:
        action?.auditRecord.createBy?.displayName ??
        shortenEmail(action?.auditRecord.createBy?.email),
    }),
    [
      action?.auditRecord.createBy?.displayName,
      action?.auditRecord.createBy?.email,
    ],
  );

  const { teamAdapter } = useTeamAdapter();

  const dotsButtonRef = useRef<HTMLButtonElement>(null);

  const onAddActionStatusCompleted = ({
    addActionStatus,
  }: AddActionStatusMutation) => {
    const variant = addActionStatus.completionRate === 100 ? 'Done' : 'Update';

    addToast({
      variant: 'success',
      children: t(`actions.toasts.addActionStatusSuccess${variant}`),
    });
  };

  const [addActionStatus] = useAddActionStatusMutation({
    onCompleted: onAddActionStatusCompleted,
    onError,
    refetchQueries: [
      {
        query: ObjectiveActionsDocument,
        variables: { objectiveId },
      },
      { query: StrategyActionsOrgDocument },
      { query: StrategyActionsOrgUnitDocument },
    ],
  });

  const [deleteAction] = useDeleteActionMutation({
    variables: { actionId: action.id },
    onError,
    update(cache) {
      setTimeout(() => cache.evict({ id: `Action:${action.id}` }), 0);
    },
    onCompleted: () =>
      addToast({
        variant: 'success',
        children: t('actions.toasts.deleteActionSuccess'),
      }),
  });

  const validationSchema = yup.object({
    completionRate: yup.number().min(0).max(100),
  });

  const initialValues: ActionStatusFormValues = {
    completionRate: action.currentStatus?.completionRate ?? 0,
  };

  const handleRemoveAction = async () => {
    await deleteAction();
    onClose();
  };

  const handleSubmit = async (values: ActionStatusFormValues) =>
    addActionStatus({
      variables: {
        input: {
          actionId: action.id,
          orgKey: teamAdapter.orgKey,
          complete: values.completionRate === 100,
          completionRate: values.completionRate,
          statusDateTime: new Date(),
        },
      },
    }).then(onClose);

  const canAddStatus = canPerformStrategyElementAction(action, 'WRITE');
  const canRequestStatus = !action.isCurrentUserOwner;

  return (
    <Form
      id={id}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, dirty, values }) => (
        <>
          <Space
            direction={'vertical'}
            size={'big'}
            className={styles.formContainer}
          >
            <Space direction={'vertical'} size={'big'} className={styles.form}>
              {canAddStatus && (
                <>
                  <Divider hasMargin={false} />
                  <FieldBox
                    name={'completionRate'}
                    label={t(
                      'initiative.milestoneStatusForm.completionRate.label',
                    )}
                    contentClassName={styles.completionRate}
                  >
                    <Field
                      disabled={!canAddStatus}
                      name={'completionRate'}
                      component={TextInputField}
                      max={100}
                      size={'small'}
                      type={'number'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const value = Number(event.target.value);
                        if (!value) {
                          setFieldValue('completionRate', 0);
                        }
                        if (value >= 0 && value <= 100) {
                          setFieldValue('completionRate', value);
                        }
                      }}
                      className={styles.completionRateTextInput}
                    />
                    <Field
                      disabled={!canAddStatus}
                      component={SliderField2}
                      size={'small'}
                      name={'completionRate'}
                      marks={{
                        0: 0,
                        25: 25,
                        50: 50,
                        75: 75,
                        100: 100,
                      }}
                      className={styles.completionRateSliderInput}
                    />
                  </FieldBox>
                </>
              )}
              <Space className={styles.auditRecord}>
                <div className={styles.auditRecordText} title={actionCreator}>
                  <Trans
                    i18nKey={'actions.attributes.createdBy'}
                    values={{
                      user: shortenedActionCreator,
                      date: date.format(
                        action.auditRecord.createDateTime,
                        'dd MMM yyy',
                      ),
                    }}
                  />
                </div>
                <div
                  className={cn(
                    styles.auditRecordText,
                    styles.auditRecordTextLastUpdate,
                  )}
                >
                  <Trans
                    i18nKey={'actions.attributes.lastUpdate'}
                    values={{
                      date: action.currentStatus?.statusDateTime
                        ? date.format(
                            action.currentStatus?.statusDateTime,
                            'dd MMM yyyy',
                          )
                        : date.format(
                            action.auditRecord.createDateTime,
                            'dd MMM yyy',
                          ),
                    }}
                  />
                </div>
              </Space>
            </Space>
          </Space>
          <Space className={styles.buttons}>
            {canAddStatus && (
              <>
                <Button
                  type={'button'}
                  variant={'outlined'}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditOpen();
                  }}
                  className={cn(styles.button, styles.buttonSecondary)}
                >
                  {t('edit')}
                </Button>
                <Menu.Button
                  type={'button'}
                  ref={dotsButtonRef}
                  onClick={toggleIsMenuOpen}
                  hasBorder={true}
                  className={cn(styles.button, styles.buttonSecondary)}
                  testId={'action-status-form-menu'}
                />
                <Menu
                  referenceElement={dotsButtonRef.current}
                  isOpen={isMenuOpen}
                  onClose={() => {
                    toggleIsMenuOpen(false);
                  }}
                  className={styles.menu}
                >
                  {canRequestStatus && (
                    <Menu.Item
                      onClick={onSendStatusRequest}
                      className={styles.menuItem}
                    >
                      <SendIcon className={styles.menuIcon} />
                      <span>{t('statusRequest.action')}</span>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    onClick={handleRemoveAction}
                    className={styles.menuItem}
                  >
                    <TrashIcon className={styles.menuIcon} />
                    <span>{t('remove')}</span>
                  </Menu.Item>
                </Menu>
              </>
            )}
            {canAddStatus ? (
              <Button
                icon={
                  values.completionRate === 100 ? CheckmarkDoneIcon : undefined
                }
                iconPosition={'start'}
                type={'submit'}
                disabled={!dirty}
                className={cn(styles.button, styles.buttonUpdate)}
              >
                {values.completionRate === 100
                  ? t('actions.buttons.completeActionStatus')
                  : t('actions.buttons.updateActionStatus')}
              </Button>
            ) : (
              <Button
                type={'button'}
                variant={'outlined'}
                onClick={onClose}
                className={cn(styles.button, styles.buttonCancel)}
              >
                {t('cancel')}
              </Button>
            )}
          </Space>
        </>
      )}
    </Form>
  );
};

export default ActionStatusForm;
