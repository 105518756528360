import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import StatusMark from 'shared/status/StatusMark';
import UserAvatar from 'user/UserAvatar';
import type { StatusIndicator } from 'shared/status/StatusIndicator';
import Flex from 'shared/components/Flex';
import LinkIcon from 'objective/LinkIcon';
import type { OrgUnitForTeamImageFragment } from 'orgUnit/OrgUnit.graphql.generated';
import TeamImage from 'team/TeamImage';
import type { OrgUnitWithAuthorizedActions } from 'user/ability/canPerformOrgUnitAction';

import type { StrategyOperationalItemOwner } from '../StrategyBoardList/StrategyBoardList.type';

const Container = styled(Link)<{ hasLink: boolean; isDisabled?: boolean }>`
  border: 1px solid
    ${({ theme, hasLink }) =>
      hasLink ? theme.color.strokeMedium : theme.color.strokeLight};
  border-radius: 8px;
  color: ${({ theme, hasLink }) =>
    hasLink ? theme.color.typoPrimary : theme.color.neutral2};
  display: flex;
  align-items: flex-start;
  gap: 14px;
  overflow: hidden;
  padding: 5px 10px 5px 15px;
  position: relative;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  cursor: ${(props) => (props.hasLink ? 'pointer' : 'default')};
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, hasLink }) =>
      hasLink && theme.color.hoverLight};
    opacity: 1;
  }
`;

const ItemName = styled.div<{ hasLink: boolean }>`
  margin-top: 3.5px;
  flex-grow: 1;

  &:hover {
    text-decoration: ${(props) => (props.hasLink ? 'underline' : 'none')};
  }
`;

const AvatarContainer = styled.div`
  height: 28px;
  width: 28px;
`;

type Props = {
  isDisabled?: boolean;
  isLink: boolean;
  linkTo?: string;
  orgUnit?: OrgUnitForTeamImageFragment & OrgUnitWithAuthorizedActions;
  owner?: Maybe<StrategyOperationalItemOwner>;
  showOrgUnit: boolean;
  statusIndicator?: Maybe<StatusIndicator>;
  textContent: Maybe<string>;
};

const StrategyBoardListRow = ({
  linkTo,
  statusIndicator,
  textContent,
  owner,
  isLink,
  isDisabled,
  orgUnit,
  showOrgUnit,
}: Props) => {
  const hasLink = !!linkTo;

  return (
    <Container hasLink={hasLink} isDisabled={isDisabled} to={linkTo || ''}>
      {linkTo && (
        <Flex alignItems={'center'} css={css({ height: 28 })}>
          <StatusMark statusIndicator={statusIndicator} hasBorder={false} />
        </Flex>
      )}

      <ItemName hasLink={hasLink}>
        {textContent}
        {isLink && <LinkIcon isInline={true} css={css({ marginLeft: 8 })} />}
      </ItemName>

      {showOrgUnit && <TeamImage orgUnit={orgUnit} size={'micro'} />}

      {owner ? <UserAvatar user={owner} /> : <AvatarContainer />}
    </Container>
  );
};

export default StrategyBoardListRow;
