import { Trans, useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

import Heading from 'shared/components/Heading';
import Stats from 'shared/components/Stats';
import Stat from 'shared/components/Stats/Stat';
import { fadeAnimation } from 'shared/animations/fade.animation';
import type { CreateMetricProps } from 'metric/CreateMetricButton/CreateMetric/CreateMetric';
import Flex from 'shared/components/Flex';
import CreateMetricButton from 'metric/CreateMetricButton';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';
import LinkMetricButton from 'metric/LinkMetricButton';
import type { LinkMetricObjectiveParam } from 'metric/LinkMetricButton/LinkMetricButton.type';
import StrategyMetricsAskSophieButton from 'metric/StrategyMetrics/StrategyMetricsAskSophieButton';

import { getMetricsStats } from './MetricsListHeader.utils';
import { useMetricsListObjectives } from '../MetricsListProvider';

const UsersStats = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundLight};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  padding: 16px;
`;

const UsersStatsText = styled(motion.div)`
  max-width: 240px;
`;

type Props = {
  afterStats?: ReactNode;
  controls?: ReactNode;
  filters?: ReactNode;
  objective?: CreateMetricProps['objective'] &
    StrategyElementWithAuthorizedActions &
    LinkMetricObjectiveParam;
};

const MetricsListHeader = ({
  objective,
  filters,
  controls,
  afterStats,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    allObjectives,
    allMetrics,
    allMetricsActive,
    allObjectivesActiveWithMetricsActive,
    isMetricsListObjectivesLoading,
  } = useMetricsListObjectives();

  const { statsActive, statDone } = useMemo(
    () => getMetricsStats(allMetrics, allMetricsActive, t, theme),
    [allMetrics, allMetricsActive, t, theme],
  );

  const hasObjectives =
    !isMetricsListObjectivesLoading && !!allObjectives?.length;

  const statsTextKey = useMemo(() => {
    if (!isMetricsListObjectivesLoading) {
      const context = objective ? 'objective' : 'strategy';

      switch (context) {
        case 'strategy':
          return hasObjectives
            ? `metrics.stats.summary.strategy.nrOfMetrics`
            : `metrics.stats.summary.strategy.noMetrics`;
        case 'objective':
          return hasObjectives
            ? `metrics.stats.summary.objective.nrOfMetrics`
            : `metrics.stats.summary.objective.noMetrics`;
      }
    } else return undefined;
  }, [hasObjectives, isMetricsListObjectivesLoading, objective]);

  return (
    <Flex direction={'column'} gap={12} css={css({ padding: '24px' })}>
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={8}>
        <Heading level={3} hasMargin={false} css={css({ flex: '1' })}>
          {t('metric.strategicMetric_other')}
        </Heading>

        {controls}

        <Flex css={css({ flex: '1' })} justifyContent={'flex-end'} gap={8}>
          {filters}
          {!objective && <StrategyMetricsAskSophieButton />}
          {objective && <LinkMetricButton objective={objective} />}
          <CreateMetricButton
            objective={objective}
            showSuccessDialog={!objective}
          >
            {t('metrics.buttons.createMetric')}
          </CreateMetricButton>
        </Flex>
      </Flex>

      <UsersStats>
        <AnimatePresence>
          {!isMetricsListObjectivesLoading && (
            <UsersStatsText {...fadeAnimation}>
              <Trans
                i18nKey={statsTextKey}
                values={{
                  metricsCount: allMetricsActive?.length,
                  objectivesCount: allObjectivesActiveWithMetricsActive?.length,
                }}
              />
            </UsersStatsText>
          )}
        </AnimatePresence>
        <Stats
          stats={statsActive}
          css={css`
            flex-basis: 460px;
            margin-left: auto;
          `}
        />
        <Stat stat={statDone} />
      </UsersStats>

      {afterStats}
    </Flex>
  );
};

export default MetricsListHeader;
