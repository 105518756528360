import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import type { PromptMessage } from 'ai/AskSohpieButton/AskSohpieButton.type';
import { useStrategy } from 'strategy/StrategyProvider';

import { useTeamInsightsOverview } from '../TeamInsightsOverviewProvider';
import AskSohpieButton from '../../../ai/AskSohpieButton';

const TeamInsightsOverviewAskSophieButton = () => {
  const { t } = useTranslation();

  const { strategy } = useStrategy();
  const { filteredObjectives } = useTeamInsightsOverview();

  const promptMessages = useMemo<PromptMessage[]>(
    () => [
      {
        content: t('ai.strategySummary.button.currentState'),
        mainPrompt: t('ai.strategySummary.button.currentState'),
        numberOfCharacters: 850,
        temperature: 1,
      },
      {
        content: t('ai.strategySummary.button.successes'),
        mainPrompt: t('ai.strategySummary.button.successes'),
        numberOfCharacters: 600,
        temperature: 1,
        hints: [],
      },
      {
        content: t('ai.strategySummary.button.improvementAreas'),
        mainPrompt: t('ai.strategySummary.button.improvementAreas'),
        numberOfCharacters: 600,
        temperature: 1,
        hints: [],
      },
      {
        content: t('ai.strategySummary.button.overview'),
        mainPrompt: t('ai.strategySummary.button.overview'),
        numberOfCharacters: 2000,
        temperature: 1,
        hints: [],
      },
    ],
    [t],
  );

  const objectiveIds = filteredObjectives.map((objective) => objective.id);

  const metricIds = filteredObjectives
    .flatMap((objective) => objective.metrics)
    .map((metric) => metric.id);

  const initiativeIds = filteredObjectives
    .flatMap((objective) => objective.initiatives)
    .map((initiative) => initiative.id);

  const actionIds = filteredObjectives
    .flatMap((objective) => objective.actions)
    .map((action) => action.id);

  return (
    <AskSohpieButton
      promptMessages={promptMessages}
      strategyId={strategy?.id}
      objectiveIds={objectiveIds}
      metricIds={metricIds}
      initiativeIds={initiativeIds}
      actionIds={actionIds}
      infoText={t('ai.strategySummary.start.subHeader.insights')}
    />
  );
};

export default TeamInsightsOverviewAskSophieButton;
