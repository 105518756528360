import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import InitiativeStatusWizard from 'initiative/InitiativeStatusWizard';
import type { InitiativeStatusInput } from 'types.graphql.generated';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import type { InitiativeStatusForStatusWizard } from 'initiative/InitiativeStatusWizard/InitiativeStatusWizard';

import { useUpdateInitiativeStatusMutation } from './UpdateInitiativeStatus.graphql.generated';

type Props = {
  initiativeId: string;
  isOpen: boolean;
  onClose: () => void;
  status: InitiativeStatusForStatusWizard;
};

const EditInitiativeStatusDialog = ({
  initiativeId,
  status,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const onError = useHandleApolloError();

  const [updateInitiativeStatus] = useUpdateInitiativeStatusMutation({
    onCompleted: onClose,
    onError,
    refetchQueries: [ContributionMyContributionDocument],
  });

  const onSubmit = useCallback(
    (input: InitiativeStatusInput) =>
      updateInitiativeStatus({ variables: { input } }),
    [updateInitiativeStatus],
  );

  return (
    <InitiativeStatusWizard
      headerTitle={t('initiative.updateInitiativeStatus.heading')}
      buttonLabel={t('update')}
      initiativeId={initiativeId}
      status={status}
      onSubmit={onSubmit}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

export default EditInitiativeStatusDialog;
