import { useTranslation } from 'react-i18next';
import compact from 'lodash/compact';
import { useMemo, useState } from 'react';

import Heading from 'shared/components/Heading';
import Calendar from 'shared/components/Calendar';
import InitiativeChip from 'initiative/InitiativeChip';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import { useTeamAdapter } from 'team/TeamAdapter';

import { useInsightReportSummaryReport } from '../InsightReportSummaryProvider';
import styles from './InsightReportSummaryStrategicInitiatives.module.scss';

type InsightReportSummaryStrategicInitiativesProps = {
  year: number;
};

const InsightReportSummaryStrategicInitiatives = ({
  year,
}: InsightReportSummaryStrategicInitiativesProps) => {
  const { t } = useTranslation();

  const { teamAdapter } = useTeamAdapter();

  const {
    report: {
      reportStatus: { reportThemeCategories },
      strategy: reportStrategy,
    },
  } = useInsightReportSummaryReport();

  const [calendarYear, setCalendarYear] = useState(year);

  const reportInitiatives = useMemo(
    () =>
      reportThemeCategories.flatMap(({ reportThemes }) =>
        reportThemes
          .flatMap(({ reportObjectives }) => reportObjectives)
          .flatMap(({ reportInitiatives, ...reportObjective }) =>
            reportInitiatives.map((reportInitiative) => ({
              reportInitiative,
              reportObjective,
            })),
          ),
      ),
    [reportThemeCategories],
  );

  const calendarItems = useMemo(
    () =>
      compact(
        reportInitiatives.map(({ reportInitiative, reportObjective }) => {
          const { initiative } = reportInitiative;
          const { startDate, endDate } = initiative.timeLine;
          if (startDate && endDate) {
            return {
              children: (
                <InitiativeChip
                  contextObjective={reportObjective.objective}
                  initiative={{
                    id: initiative.id,
                    name: initiative.name,
                    currentInitiativeStatus: reportInitiative.status,
                    objective: initiative.objective,
                  }}
                  teamAdapter={teamAdapter}
                />
              ),
              startDateTime: startDate,
              endDateTime: endDate,
            };
          }
        }),
      ),
    [reportInitiatives, teamAdapter],
  );

  const calendarDates = {
    start: new Date(calendarYear, 0),
    end: new Date(new Date(calendarYear + 1, 0).getTime() - 1),
  };

  return (
    <>
      <Heading level={3}>
        {t('report.insightReportSummary.strategicInitiatives.heading', {
          year,
        })}
      </Heading>
      {reportStrategy?.vision?.name ? (
        <Text className={styles.visionName}>
          {reportStrategy?.vision?.name}
        </Text>
      ) : null}
      <Space direction={'vertical'} size={'large'}>
        <Calendar.Controls year={calendarYear} onYearChange={setCalendarYear} />
        <Calendar
          startDateTime={calendarDates.start}
          endDateTime={calendarDates.end}
          intervalUnit={'month'}
          items={calendarItems}
        />
      </Space>
    </>
  );
};

export default InsightReportSummaryStrategicInitiatives;
