import cn from 'classnames';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from 'shared/static/icons/icon-calendar.svg';
import UserAvatar from 'user/UserAvatar';
import { date } from 'shared/services/date.service';
import { StatusIndicator } from 'shared/status/StatusIndicator';
import StatusMark from 'shared/status/StatusMark';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import MetricContextMenu from 'metric/MetricContextMenu/MetricContextMenu';
import TeamImage from 'team/TeamImage';
import TeamLink from 'team/TeamLink';
import MaybeLinkIcon from 'objective/LinkIcon/MaybeLinkIcon';

import styles from './MetricRow.module.scss';
import type {
  MetricsListMetric,
  MetricsListObjective,
} from '../MetricsListProvider/MetricsListProvider.type';

type MetricRowProps = {
  metric: MetricsListMetric;
  objective: MetricsListObjective;
};

const MetricRow = ({ metric, objective }: MetricRowProps) => {
  const { t } = useTranslation();

  const statusIndicator =
    metric.currentMetricStatus?.statusIndicator || new StatusIndicator();

  const { teamAdapter } = useTeamAdapter();

  const navigate = useNavigate();

  const navigateToMetric = () =>
    objective &&
    navigate(
      objectiveRoutesPaths.metrics.metric.root({
        params: {
          objectiveId: objective.id,
          teamSlug: teamAdapter.toParam(),
          metricId: metric.id,
        },
      }),
    );

  const timelineRangeText = useMemo(() => {
    const dateEndTime =
      metric.timeLine?.endDate ?? metric.timeLine?.completeDateTime;

    const hasDates = !!(metric.timeLine?.startDate || dateEndTime);

    const startDateText = metric.timeLine?.startDate
      ? date.format(metric.timeLine?.startDate, 'MMM yyyy')
      : t('notSet');

    const endDateText = dateEndTime
      ? date.format(
          metric.timeLine?.endDate ?? metric.timeLine?.completeDateTime,
          'MMM yyyy',
        )
      : t('notSet');

    return hasDates ? `${startDateText} - ${endDateText}` : null;
  }, [
    metric.timeLine?.completeDateTime,
    metric.timeLine?.endDate,
    metric.timeLine?.startDate,
    t,
  ]);

  return (
    <motion.li className={styles.row} onClick={navigateToMetric} layout={true}>
      <motion.div layout={true} className={styles.name}>
        <StatusMark statusIndicator={statusIndicator} hasBorder={false} />
        <p className={styles.nameText}>{metric.name}</p>
        <MaybeLinkIcon strategyElement={metric} contextObjective={objective} />
      </motion.div>
      <motion.div layout={true} className={cn(styles.timeline)}>
        {timelineRangeText && (
          <>
            <CalendarIcon className={styles.timelineIcon} />
            <motion.div layout={true} className={cn(styles.timelineText)}>
              {timelineRangeText}
            </motion.div>
          </>
        )}
      </motion.div>
      <TeamLink orgUnit={metric.objective?.orgUnit}>
        <TeamImage size={'tiny'} orgUnit={metric.objective?.orgUnit} />
      </TeamLink>
      {metric.owner ? (
        <UserAvatar
          user={metric.owner}
          containerClassName={styles.avatarContainer}
        />
      ) : (
        <div className={styles.avatarContainerEmpty} />
      )}
      <MetricContextMenu metric={metric} contextObjective={objective} />
    </motion.li>
  );
};

export default MetricRow;
