import { useLocation } from 'react-router-dom';
import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContributionMyFollowUpsSection from 'contribution/ContributionMyFollowUpsSection';
import ContributionLineOfSightSection from 'contribution/ContributionLineOfSightSection';
import ContributionStrategySection from 'contribution/ContributionStrategySection';
import Spinner from 'shared/spinner/Spinner';
import ContributionOthersCommitments from 'contribution/ContributionOthersCommitments';
import UserDetails from 'contribution/ContributionUserContribution/UserDetails';
import useHandleApolloError from 'shared/errors/useHandleApolloError';

import { useContributionMyContributionQuery } from './ContributionMyContribution.graphql.generated';

const ContributionMyContribution = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [hasScrolled, setHasScrolled] = useState(false);

  const myStrategyRef = useRef<HTMLElement | null>(null);
  const myMembershipsRef = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    if (state?.scrollMyStrategyIntoView && !hasScrolled) {
      myStrategyRef.current?.scrollIntoView({ behavior: 'smooth' });
      setHasScrolled(true);
    }
  }, [hasScrolled, state]);

  const handleApolloError = useHandleApolloError();

  const { data } = useContributionMyContributionQuery({
    fetchPolicy: 'cache-and-network',
    onError: handleApolloError,
  });

  if (!data) {
    return <Spinner.Circle />;
  }

  return (
    <>
      <UserDetails user={data.currentUser} />
      <ContributionLineOfSightSection
        user={data.currentUser}
        subject={'self'}
      />
      <ContributionMyFollowUpsSection user={data.currentUser} />
      <ContributionStrategySection
        user={data.currentUser}
        contributions={data.currentUser.contributingTo.asOwner}
        ref={myStrategyRef}
        title={t(`contribution.strategySection.heading.self`)}
        description={t(`contribution.strategySection.description.self`)}
        emptyMessage={t(`contribution.strategySection.emptyDescription.self`)}
      />
      <ContributionOthersCommitments
        contributions={data.currentUser.contributingTo.othersRelatedItems}
      />
      <ContributionStrategySection
        user={data.currentUser}
        contributions={data.currentUser.contributingTo.asTeamMember}
        ref={myMembershipsRef}
        title={t(`contribution.myMemberships.self.heading`)}
        description={t(`contribution.myMemberships.self.description`)}
        emptyMessage={t(`contribution.myMemberships.self.emptyDescription`)}
      />
    </>
  );
};

export default ContributionMyContribution;
