import type * as Types from '../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import { UserLimitedProfileFragmentDoc } from '../../user/userProfile.graphql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActionStatusModalQueryVariables = Types.Exact<{
  actionId: Types.Scalars['ID'];
}>;


export type ActionStatusModalQuery = { __typename: 'Query', action: { __typename: 'Action', id: string, name?: string | undefined, description?: string | undefined, isCurrentUserOwner: boolean, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions>, objective?: { __typename: 'Objective', id: string, name?: string | undefined, currentUserAuthorizedActions: Array<Types.StrategyElementAuthorizedActions> } | undefined, currentStatus?: { __typename: 'ActionStatus', id: string, completionRate?: number | undefined, complete: boolean, statusDateTime: any } | undefined, auditRecord: { __typename: 'AuditRecord', createDateTime?: any | undefined, createBy?: { __typename: 'AuditRecordUser', email?: string | undefined, displayName?: string | undefined } | undefined }, timeLine: { __typename: 'TimeLine', endDate?: any | undefined }, owner?: { __typename: 'UserLimited', id: string, email: string, displayName?: string | undefined, photoUrl?: string | undefined, isActive: boolean } | undefined } };


export const ActionStatusModalDocument = gql`
    query ActionStatusModal($actionId: ID!) {
  action(id: $actionId) {
    id
    name
    description
    isCurrentUserOwner
    currentUserAuthorizedActions
    objective {
      id
      name
      currentUserAuthorizedActions
    }
    currentStatus {
      id
      completionRate
      complete
      statusDateTime
    }
    auditRecord {
      createDateTime
      createBy {
        email
        displayName
      }
    }
    timeLine {
      endDate
    }
    owner {
      ...userLimitedProfile
    }
  }
}
    ${UserLimitedProfileFragmentDoc}`;

/**
 * __useActionStatusModalQuery__
 *
 * To run a query within a React component, call `useActionStatusModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionStatusModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionStatusModalQuery({
 *   variables: {
 *      actionId: // value for 'actionId'
 *   },
 * });
 */
export function useActionStatusModalQuery(baseOptions: Apollo.QueryHookOptions<ActionStatusModalQuery, ActionStatusModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionStatusModalQuery, ActionStatusModalQueryVariables>(ActionStatusModalDocument, options);
      }
export function useActionStatusModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionStatusModalQuery, ActionStatusModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionStatusModalQuery, ActionStatusModalQueryVariables>(ActionStatusModalDocument, options);
        }
export type ActionStatusModalQueryHookResult = ReturnType<typeof useActionStatusModalQuery>;
export type ActionStatusModalLazyQueryHookResult = ReturnType<typeof useActionStatusModalLazyQuery>;
export type ActionStatusModalQueryResult = Apollo.QueryResult<ActionStatusModalQuery, ActionStatusModalQueryVariables>;