import type * as Types from '../../../types.graphql.generated';

import { StatusIndicator as StatusIndicatorClass } from 'shared/status/StatusIndicator';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AskSophieSidePanelSubscriptionVariables = Types.Exact<{
  mainPrompt: Types.Scalars['String'];
  numberOfCharacters?: Types.InputMaybe<Types.Scalars['Int']>;
  temperature?: Types.InputMaybe<Types.Scalars['Float']>;
  languageLocaleCode: Types.Scalars['String'];
  hints?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  objectiveIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  metricIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  initiativeIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  actionIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  strategyIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type AskSophieSidePanelSubscription = { __typename: 'Subscription', aiStream: string };


export const AskSophieSidePanelDocument = gql`
    subscription AskSophieSidePanel($mainPrompt: String!, $numberOfCharacters: Int, $temperature: Float, $languageLocaleCode: String!, $hints: [String!], $objectiveIds: [ID!], $metricIds: [ID!], $initiativeIds: [ID!], $actionIds: [ID!], $strategyIds: [ID!]) {
  aiStream(
    input: {mainPrompt: $mainPrompt, model: {temperature: $temperature}, maxNumberOfCharacters: $numberOfCharacters, languageLocaleCode: $languageLocaleCode, hints: $hints, elements: {objectiveIds: $objectiveIds, metricIds: $metricIds, initiativeIds: $initiativeIds, actionIds: $actionIds, strategyIds: $strategyIds}}
  )
}
    `;

/**
 * __useAskSophieSidePanelSubscription__
 *
 * To run a query within a React component, call `useAskSophieSidePanelSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAskSophieSidePanelSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAskSophieSidePanelSubscription({
 *   variables: {
 *      mainPrompt: // value for 'mainPrompt'
 *      numberOfCharacters: // value for 'numberOfCharacters'
 *      temperature: // value for 'temperature'
 *      languageLocaleCode: // value for 'languageLocaleCode'
 *      hints: // value for 'hints'
 *      objectiveIds: // value for 'objectiveIds'
 *      metricIds: // value for 'metricIds'
 *      initiativeIds: // value for 'initiativeIds'
 *      actionIds: // value for 'actionIds'
 *      strategyIds: // value for 'strategyIds'
 *   },
 * });
 */
export function useAskSophieSidePanelSubscription(baseOptions: Apollo.SubscriptionHookOptions<AskSophieSidePanelSubscription, AskSophieSidePanelSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AskSophieSidePanelSubscription, AskSophieSidePanelSubscriptionVariables>(AskSophieSidePanelDocument, options);
      }
export type AskSophieSidePanelSubscriptionHookResult = ReturnType<typeof useAskSophieSidePanelSubscription>;
export type AskSophieSidePanelSubscriptionResult = Apollo.SubscriptionResult<AskSophieSidePanelSubscription>;