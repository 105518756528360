import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AskSohpieButton from 'ai/AskSohpieButton';
import type { PromptMessage } from 'ai/AskSohpieButton/AskSohpieButton.type';
import { useStrategy } from 'strategy/StrategyProvider';

import { useStrategyInitiatives } from './StrategyInitiativesProvider';

const StrategyInitiativesAskSophieButton = () => {
  const { t } = useTranslation();

  const { strategy } = useStrategy();
  const { objectives } = useStrategyInitiatives();

  const objectiveIds = objectives?.map((objective) => objective.id);

  const initiativeIds = objectives
    ?.flatMap((objective) => objective.initiatives)
    .map((initiative) => initiative.id);

  const promptMessages = useMemo<PromptMessage[]>(
    () => [
      {
        content: t('ai.strategySummary.button.initiativeAlignment'),
        mainPrompt:
          'Please analyze if our initiatives are aligned. Focus on alignment, not on the status of each element.',
        numberOfCharacters: 5000,
        temperature: 1,
        hints: [
          'Analyze alignment of the given initiatives.',
          'In your response focus on alignment not on the status of each initiative.',
          'Group the results first per theme, then within each theme group by a) Seems to be well aligned, b) potentially misaligned.',
        ],
      },
    ],
    [t],
  );

  return (
    <AskSohpieButton
      promptMessages={promptMessages}
      strategyId={strategy?.id}
      objectiveIds={objectiveIds}
      initiativeIds={initiativeIds}
      infoText={t('ai.strategySummary.start.subHeader.strategyInitiatives')}
    />
  );
};

export default StrategyInitiativesAskSophieButton;
