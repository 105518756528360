import type { ReactNode } from 'react';
import { useCallback, useRef, useMemo, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import { ReactComponent as CalendarIcon } from 'shared/static/icons/icon-calendar.svg';
import Space from 'shared/components/Space';
import Text from 'shared/components/Text';
import { ReactComponent as LinkIcon } from 'shared/static/icons/icon-link.svg';
import StatusMark from 'shared/status/StatusMark/StatusMark';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import UserAvatar from 'user/UserAvatar/UserAvatar';
import { date } from 'shared/services/date.service';
import type { Org } from 'types.graphql.generated';
import { TeamAdapter } from 'team/TeamAdapter';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import Flex from 'shared/components/Flex';
import OptionalLink from 'shared/components/OptionalLink';
import TeamLink from 'team/TeamLink';
import TeamImage from 'team/TeamImage';
import ObjectiveContextMenu from 'objective/ObjectiveContextMenu';

import type { StrategyObjectivesObjective } from '../StrategyObjectives.type';
import styles from './StrategyObjectivesObjectiveRow.module.scss';

type StrategyObjectivesObjectiveRowProps = {
  dropdownButton: ReactNode;
  isHighlighted?: boolean;
  objective: StrategyObjectivesObjective;
  orgKey: Org['orgKey'];
};

const StrategyObjectivesObjectiveRow = ({
  objective,
  dropdownButton,
  isHighlighted = false,
  orgKey,
}: StrategyObjectivesObjectiveRowProps) => {
  const { t } = useTranslation();

  const rowRef = useRef<HTMLDivElement>(null);

  const teamAdapter = useMemo(
    () =>
      objective.orgUnit
        ? TeamAdapter.fromOrgUnit(objective.orgUnit, orgKey)
        : TeamAdapter.fromOrg({ orgKey }),
    [objective, orgKey],
  );

  const renderRowContent = useMemo(
    () =>
      objective.childObjectives.length ? (
        <>
          <LinkIcon className={styles.linkIcon} />
          <Trans
            i18nKey={'objective.relatedObjectives.relatedObjectivesCount'}
            values={{
              relatedObjectives: t('objective.childObjective', {
                count: objective.childObjectives.length,
              }),
              count: objective.childObjectives.length,
            }}
          />
        </>
      ) : (
        t('objective.noChildObjectives')
      ),
    [objective, t],
  );

  const setPosition = useCallback((el: Maybe<HTMLDivElement>) => {
    const PADDING_HEIGHT = '1.5rem';
    if (el && rowRef.current) {
      el.style.height = `calc(${
        rowRef.current.getBoundingClientRect().height
      }px + ${PADDING_HEIGHT})`;
    }
  }, []);

  const { startDate, endDate } = useMemo(() => {
    const startDate = objective.timeLine.startDate
      ? date.format(objective.timeLine.startDate, 'dd MMM yyyy')
      : t('notSet');
    const endDate = objective.timeLine.endDate
      ? date.format(objective.timeLine.endDate, 'dd MMM yyyy')
      : t('notSet');

    return {
      startDate,
      endDate,
    };
  }, [objective, t]);

  const canReadObjective = canPerformStrategyElementAction(objective, 'READ');
  const objectiveLink = objectiveRoutesPaths.root({
    params: { teamSlug: teamAdapter.toParam(), objectiveId: objective?.id },
  });

  return (
    <>
      <Space
        className={styles.details}
        ref={rowRef}
        css={css({ opacity: objective.isFiltersMatch ? 1 : 0.5 })}
      >
        <StatusMark
          statusIndicator={objective.currentObjectiveStatus?.statusIndicator}
          hasBorder={false}
        />
        <Space
          direction={'horizontal'}
          size={'custom'}
          value={10}
          className={styles.header}
        >
          <Space direction={'vertical'} size={'custom'} value={0}>
            <Flex alignItems={'center'} gap={8}>
              <OptionalLink to={objectiveLink} isActive={canReadObjective}>
                <Text variant={'emphasis'} ellipsis={true}>
                  {objective?.name}
                </Text>
              </OptionalLink>

              {dropdownButton}
            </Flex>
            <Text className={styles.childObjectives}>{renderRowContent}</Text>
          </Space>
          <Space
            direction={'horizontal'}
            size={'custom'}
            className={styles.metadata}
            value={12}
          >
            <Space
              direction={'horizontal'}
              size={'custom'}
              className={styles.date}
            >
              <CalendarIcon className={styles.dateIcon} />
              <Text>{`${startDate} - ${endDate}`}</Text>
            </Space>
            <TeamLink orgUnit={objective.orgUnit}>
              <TeamImage orgUnit={objective.orgUnit} size={'tiny'} />
            </TeamLink>
            <UserAvatar user={objective.owner} />
          </Space>
          <ObjectiveContextMenu
            objective={objective}
            triggerStyle={css`
              width: 32px;
              height: 32px;
            `}
          />
        </Space>
      </Space>
      {isHighlighted && <div className={styles.highlight} ref={setPosition} />}
    </>
  );
};

export default memo(StrategyObjectivesObjectiveRow);
