import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import { ReactComponent as SendIcon } from 'shared/static/icons/icon-send.svg';
import useDialogState from 'shared/hooks/useDialogState';

import type { StrategyElement } from './useSendStatusRequest';
import SendStatusRequestsDialog from './SendStatusRequestsDialog';

type Props = {
  strategyElements: (StrategyElement & { owner?: { id: string } })[];
};

const SendMultipleStatusRequestButton = ({ strategyElements }: Props) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDialogState();

  const isEmpty = strategyElements.length === 0;

  return (
    <>
      <Button
        icon={SendIcon}
        iconPosition={'start'}
        variant={'outlined'}
        onClick={onOpen}
        disabled={isEmpty}
      >
        {t('statusRequest.action')}
      </Button>

      <SendStatusRequestsDialog
        strategyElements={strategyElements}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default SendMultipleStatusRequestButton;
