import styled from '@emotion/styled';

import type { Message } from './AskSophieSidePanel';

const ChatBubble = styled.div<{ messageType: Message['type'] }>`
  background-color: ${(props) =>
    props.messageType === 'SummaryMessage'
      ? props.theme.color.backgroundLight
      : props.theme.color.primaryVariant};
  padding: 16px;
  border-radius: 16px;
  white-space: pre-wrap;
`;
export default ChatBubble;
