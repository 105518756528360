import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { capitalize } from 'lodash';

import type { MembersProps } from 'user/Members';
import { useToasts } from 'shared/toast/useToasts';
import { UserEmailInputOrgEmployeesDocument } from 'user/UserEmailInput';
import Members from 'user/Members';
import ObjectiveLink from 'objective/ObjectiveLink';
import SiderDescription from 'shared/components/Description';
import DescriptionDates from 'shared/components/DescriptionDates/DescriptionDates';
import { useTeamAdapter } from 'team/TeamAdapter';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';
import { useUpdateMetricMutation } from 'metric/EditMetric/EditMetric.graphql.generated';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import Heading from 'shared/components/Heading';
import Flex from 'shared/components/Flex';

import styles from './MetricOverviewSider.module.scss';
import { useMetricOverview } from '../MetricOverviewProvider';
import { MetricOverviewDocument } from '../MetricOverviewProvider/MetricOverviewProvider.graphql.generated';

const MetricOverviewSider = () => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const { teamAdapter } = useTeamAdapter();
  const { metric } = useMetricOverview();

  const handleApolloError = useHandleApolloError();

  const [updateMetric] = useUpdateMetricMutation({
    onError: handleApolloError,
    refetchQueries: [
      UserEmailInputOrgEmployeesDocument,
      {
        query: MetricOverviewDocument,
        variables: { metricId: metric?.id },
      },
      ContributionMyContributionDocument,
    ],
  });

  const usersToExcludeFromAddingToTeam = useMemo(() => {
    const ownerUser = metric?.owner ? { id: metric.owner.id } : undefined;
    return metric?.teamMembers
      ? [
          ownerUser,
          ...metric.teamMembers.map((teamMember) => {
            const userId = teamMember?.user?.id;
            if (userId) return { id: userId };
          }),
        ].filter(Boolean)
      : [];
  }, [metric]);

  const handleAddTeamMember: NonNullable<
    MembersProps['onAddNewMember']
  > = async (data, closeModal) => {
    await updateMetric({
      variables: {
        input: {
          idToUpdate: metric?.id,
          teamMembers: {
            add: [
              {
                user: { emailToSet: data.user },
                ...(data.role && {
                  role: data.role,
                }),
              },
            ],
          },
        },
      },
      onCompleted: () => {
        closeModal();
        addToast({
          children: t('metric.metricLayout.addTeamMemberSuccessToast'),
          variant: 'success',
        });
      },
    });
  };

  const handleRemoveTeamMember: NonNullable<
    MembersProps['onRemoveMember']
  > = async (member) => {
    await updateMetric({
      variables: {
        input: {
          idToUpdate: metric?.id,
          teamMembers: {
            idsToDelete: [member.id],
          },
        },
      },
      onCompleted: () => {
        addToast({
          children: t('metric.metricLayout.removeTeamMemberSuccessToast', {
            name: member.user?.displayName || member.user?.email,
          }),
          variant: 'success',
        });
      },
    });
  };

  if (!metric) return null;

  const canWrite = canPerformStrategyElementAction(metric, 'WRITE');

  return (
    <div className={styles.container}>
      <SiderDescription description={metric.description} />
      <DescriptionDates
        startDate={metric.timeLine.startDate}
        endDate={metric.timeLine.endDate}
        completeDateTime={metric.timeLine.completeDateTime}
      />
      <Heading level={3} as={5} hasMargin={false}>
        {capitalize(t('objective.objective'))}
      </Heading>
      {metric.objective && (
        <ObjectiveLink objective={metric.objective} isLink={false} />
      )}
      {metric.linkedObjectives.length > 0 && (
        <Heading level={3} as={5} hasMargin={false}>
          {t('objective.linkedObjective_other')}
        </Heading>
      )}
      <Flex direction={'column'} gap={8}>
        {metric.linkedObjectives.map((objective) => (
          <ObjectiveLink
            key={objective.id}
            objective={objective}
            className={styles.link}
            isLink={true}
          />
        ))}
      </Flex>
      <Members
        owner={metric.owner}
        members={metric.teamMembers.map((teamMember) => ({
          id: teamMember.id,
          role: teamMember.role,
          ...(teamMember.user && {
            user: {
              id: teamMember.user?.id,
              email: teamMember.user?.email,
              displayName: teamMember.user?.displayName,
              photoUrl: teamMember.user?.photoUrl,
              isActive: teamMember.user?.isActive,
            },
          }),
        }))}
        teamAdapter={teamAdapter}
        usersToExclude={usersToExcludeFromAddingToTeam}
        canAddNewMember={canWrite}
        onAddNewMember={handleAddTeamMember}
        canRemoveMember={canWrite}
        onRemoveMember={handleRemoveTeamMember}
      />
    </div>
  );
};

export default MetricOverviewSider;
