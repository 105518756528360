import OptionalLink from 'shared/components/OptionalLink';

import type { LinkedItem } from '../StrategyOperationalItemRow.type';

type StrategyOperationalItemRowLinkProps = {
  color?: string;
  linkedItem: LinkedItem;
};

const StrategyOperationalItemRowLink = ({
  linkedItem,
  color,
}: StrategyOperationalItemRowLinkProps) => {
  return (
    <OptionalLink
      to={linkedItem.linkTo}
      color={color}
      isActive={!linkedItem.isLocked}
      isInline={true}
    >
      {linkedItem.text}
    </OptionalLink>
  );
};

export default StrategyOperationalItemRowLink;
