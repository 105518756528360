import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { ObjectiveDocument } from 'objective/ObjectiveProvider';
import type { ObjectiveFormValues } from 'objective/EditObjective/ObjectiveForm';
import { resolveInitialValue as resolveSelectObjectiveInputInitialValue } from 'objective/SelectObjectiveInput';
import { resolveInitialValue as resolveSelectThemeInputInitialValue } from 'objective/SelectThemeInput';
import ObjectiveForm from 'objective/EditObjective/ObjectiveForm';
import type { ObjectiveInput } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import { resolvePriorityInitialValue } from 'strategy/PrioritySelect/resolvePriorityInitialValue';
import useHandleApolloError from 'shared/errors/useHandleApolloError';
import { resolveOrgUnitInputInitialValue } from 'orgUnit/SelectOrgUnitInput/SelectOrgUnitInput.utils';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import { useUpdateObjectiveMutation } from './UpdateObjective.graphql.generated';
import ObjectiveFormModal from './ObjectiveFormModal/ObjectiveFormModal';
import { useEditObjectiveQuery } from './EditObjective.graphql.generated';

type Props = {
  isOpen: boolean;
  objectiveId: string;
  onClose: () => void;
};

const EditObjective = ({ objectiveId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();
  const { addToast } = useToasts();

  const onError = useHandleApolloError();

  const { data } = useEditObjectiveQuery({
    variables: { objectiveId },
    onError,
    skip: !isOpen,
  });

  const [updateObjective] = useUpdateObjectiveMutation({
    onError,
    refetchQueries: [ObjectiveDocument],
  });

  const objective = data?.objective;

  const initialValues: ObjectiveFormValues | undefined = useMemo(
    () =>
      objective
        ? {
            id: objective.id,
            name: objective.name || '',
            description: objective.description || '',
            owner: objective.owner?.email || '',
            parentObjective: resolveSelectObjectiveInputInitialValue(
              objective.parentObjective,
            ),
            theme: resolveSelectThemeInputInitialValue(objective.theme),
            priority: resolvePriorityInitialValue(objective.priority),
            timeLine: {
              startDate: objective.timeLine.startDate,
              endDate: objective.timeLine.endDate,
            },
            attributes: objective.attributes.map((attribute) => ({
              id: attribute.id,
              name: attribute.name || '',
              title: attribute.title || '',
              description: attribute.description || '',
            })),
            orgUnit: resolveOrgUnitInputInitialValue(
              activeOrg,
              objective.orgUnit,
            ),
          }
        : undefined,
    [activeOrg, objective],
  );

  const handleSubmit = useCallback(
    async (input: ObjectiveInput) => {
      const result = await updateObjective({ variables: { input } });

      const isSuccess = result.data?.updateObjective.id && !result.errors;

      if (isSuccess) {
        addToast({
          variant: 'success',
          children: t('objective.editObjectiveModal.successToast'),
        });

        onClose();
      }
    },
    [addToast, onClose, t, updateObjective],
  );

  if (!initialValues) return null;

  const formId = `objective_${objective?.id}`;

  return (
    <ObjectiveForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formId={formId}
    >
      <ObjectiveFormModal
        headerTitle={t('objective.editObjectiveModal.heading')}
        confirmLabel={t('update')}
        isOpen={true}
        onClose={onClose}
        formId={formId}
      />
    </ObjectiveForm>
  );
};

export default EditObjective;
