import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Space from 'shared/components/Space';
import Layout from 'shared/components/Layout';
import { useStrategy } from 'strategy/StrategyProvider';
import { ReactComponent as IconWalk } from 'shared/static/icons/icon-walk.svg';
import { ReactComponent as IconLayoutHorizontal } from 'shared/static/icons/icon-layout-horizontal.svg';
import { useLayoutBackground } from 'shared/components/Layout/useLayoutBackground';
import lakesAndTrees from 'shared/static/backgrounds/background-lakes-and-trees.svg';
import Button from 'shared/components/Button';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { useTeamAdapter } from 'team/TeamAdapter';
import Heading from 'shared/components/Heading';
import IfCanPerformOrgOrOrgUnitAction from 'user/ability/IfCanPerformOrgOrOrgUnitAction';

import styles from './StrategyOverview.module.scss';
import PillBar from './PillBar';
import StrategyOverviewCore from '../StrategyOverviewCore';
import StrategyOverviewMap from '../StrategyOverviewMap';
import NoStrategyContent from '../NoStrategyContent';
import ThemesProvider from '../../themes/ThemesProvider';
import StrategyOverviewAskSophieButton from './StrategyOverviewAskSophieButton';

const SwitchOptions = [
  {
    value: 'map',
    label: <IconWalk />,
  },
  {
    value: 'core',
    label: <IconLayoutHorizontal />,
  },
];

const StrategyOverviewScreens = {
  [SwitchOptions[0].value]: <StrategyOverviewMap />,
  [SwitchOptions[1].value]: (
    <ThemesProvider>
      <StrategyOverviewCore />
    </ThemesProvider>
  ),
};

const StrategyOverview = () => {
  const { strategy } = useStrategy();
  const { t } = useTranslation();
  const [mode, setMode] = useState(SwitchOptions[0].value);
  const navigate = useNavigate();
  const { teamAdapter } = useTeamAdapter();

  const openStrategyRoom = () => {
    navigate(
      teamRoutesPaths.strategyRoom({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    );
  };

  useLayoutBackground({
    image: lakesAndTrees,
    className: styles.background,
    skip: !strategy,
  });

  if (!strategy) return <NoStrategyContent />;

  return (
    <Layout.Content hasPadding={false}>
      <Space direction={'vertical'} size={'large'}>
        <div className={styles.card}>
          <Heading level={3} hasMargin={false} ellipsis={true}>
            {strategy.name}
          </Heading>
          <Space direction={'horizontal'}>
            <PillBar
              options={SwitchOptions}
              value={mode}
              onChange={setMode}
              fieldId={'strategy-overview-switch'}
            />
            <IfCanPerformOrgOrOrgUnitAction
              orgAction={'WRITE_STRATEGY'}
              orgUnitAction={'WRITE'}
            >
              <Button variant={'outlined'} onClick={openStrategyRoom}>
                {t('strategy.strategyRoom.openStrategyRoom')}
              </Button>
            </IfCanPerformOrgOrOrgUnitAction>
            <StrategyOverviewAskSophieButton />
          </Space>
        </div>
        {StrategyOverviewScreens[mode]}
      </Space>
    </Layout.Content>
  );
};

export default StrategyOverview;
