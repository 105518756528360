import compact from 'lodash/compact';
import { capitalize } from 'lodash';

import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { ReactComponent as OverviewIcon } from 'shared/static/icons/icon-overview.svg';
import { ReactComponent as DocumentIcon } from 'shared/static/icons/icon-document.svg';
import { ReactComponent as InsightsIcon } from 'shared/static/icons/icon-insights.svg';
import { ReactComponent as RelatedItemsIcon } from 'shared/static/icons/icon-related-items.svg';
import { ReactComponent as MetricIcon } from 'shared/static/icons/icon-metric.svg';
import { ReactComponent as InitiativesIcon } from 'shared/static/icons/icon-initiatives.svg';
import { ReactComponent as ActionIcon } from 'shared/static/icons/icon-actions.svg';
import { ReactComponent as HierarchyIcon } from 'shared/static/icons/icon-hierarchy.svg';
import type { LinkTabItem } from 'shared/components/LinkTabs/LinkTabs.type';
import { strategyRoutesPaths } from 'strategy/strategy.routing.paths';
import { TeamAdapter } from 'team/TeamAdapter';
import { i18n } from 'shared/services/i18n.service';
import { teamRoutesPaths } from 'team/team.routing.paths';
import type {
  Objective,
  Org,
  OrgUnit,
  Strategy,
} from 'types.graphql.generated';
import {
  canPerformStrategyElementAction,
  type StrategyElementWithAuthorizedActions,
} from 'user/ability/canPerformStrategyElementAction';

type ParentOrgUnitTree = Array<
  Pick<OrgUnit, 'id' | 'name'> & {
    parentOrgUnit?: Maybe<Pick<OrgUnit, 'id'>>;
  }
>;

export const getBreadcrumbItems = (params: {
  activeOrg: Pick<Org, 'orgKey' | 'displayName'> & {
    currentStrategy?: Pick<Strategy, 'id'>;
  };
  objectiveId: string;
  orgUnit?: Maybe<
    Pick<OrgUnit, 'id' | 'name'> & {
      parentOrgUnitTree: ParentOrgUnitTree;
    }
  >;
  teamAdapter: TeamAdapter;
}) => {
  const { activeOrg, orgUnit, teamAdapter } = params;

  const parentOrgUnits: ParentOrgUnitTree = [];
  if (orgUnit?.parentOrgUnitTree?.length) {
    parentOrgUnits.push(
      orgUnit.parentOrgUnitTree.find((item) => !item?.parentOrgUnit) as OrgUnit,
    );
    for (let i = 0; i < orgUnit?.parentOrgUnitTree?.length - 1; i++) {
      const find = orgUnit.parentOrgUnitTree.find(
        (item) =>
          item.parentOrgUnit?.id ===
          parentOrgUnits[parentOrgUnits.length - 1]?.id,
      );
      if (find) {
        parentOrgUnits.push(find);
      }
    }
  }

  return compact([
    {
      name: activeOrg.displayName as string,
      to: strategyRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrg(activeOrg).toParam(),
        },
      }),
    },
    ...parentOrgUnits.map((item) => ({
      name: item.name as string,
      to: teamRoutesPaths.root({
        params: {
          teamSlug: TeamAdapter.fromOrgUnit(item, activeOrg.orgKey).toParam(),
        },
      }),
    })),
    orgUnit
      ? {
          name: orgUnit.name as string,
          to: teamRoutesPaths.root({
            params: {
              teamSlug: TeamAdapter.fromOrgUnit(
                orgUnit,
                activeOrg.orgKey,
              ).toParam(),
            },
          }),
        }
      : undefined,
    {
      name: capitalize(i18n.t('objective.strategicObjective_other')),
      to: strategyRoutesPaths.objectives.root({
        params: {
          teamSlug: teamAdapter.toParam(),
        },
      }),
    },
    {
      name: capitalize(i18n.t('objective.objective')),
    },
  ]);
};

export const getNavigationItems = (params: {
  objective: Pick<Objective, 'id'> & StrategyElementWithAuthorizedActions;
  teamAdapter: TeamAdapter;
}): LinkTabItem[] => {
  const { objective, teamAdapter } = params;

  const objectiveId = objective.id;

  const canReadStatusComments = canPerformStrategyElementAction(
    objective,
    'READ_STATUS_COMMENTS',
  );

  return [
    {
      name: i18n.t('objective.objectiveLayout.header.navigation.overview'),
      icon: OverviewIcon,
      to: objectiveRoutesPaths.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId,
        },
      }),
      end: true,
    },
    {
      name: i18n.t('objective.objectiveLayout.header.navigation.details'),
      to: objectiveRoutesPaths.details({
        params: { teamSlug: teamAdapter.toParam(), objectiveId },
      }),
      icon: DocumentIcon,
      end: true,
    },
    canReadStatusComments && {
      name: i18n.t('objective.objectiveLayout.header.navigation.insights'),
      to: objectiveRoutesPaths.insights.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId,
        },
      }),
      icon: InsightsIcon,
      end: false,
    },
    {
      name: i18n.t(
        'objective.objectiveLayout.header.navigation.relatedObjectives',
      ),
      to: objectiveRoutesPaths.relatedObjectives({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId,
        },
      }),
      icon: RelatedItemsIcon,
      end: true,
    },
    {
      name: i18n.t('objective.objectiveLayout.header.navigation.alignment'),
      to: objectiveRoutesPaths.alignment({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId,
        },
      }),
      icon: HierarchyIcon,
      end: true,
    },
    {
      name: i18n.t('objective.objectiveLayout.header.navigation.metrics'),
      to: objectiveRoutesPaths.metrics.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId,
        },
      }),
      icon: MetricIcon,
      end: true,
    },
    {
      name: i18n.t('objective.objectiveLayout.header.navigation.initiatives'),
      to: objectiveRoutesPaths.initiatives.root({
        params: { teamSlug: teamAdapter.toParam(), objectiveId },
      }),
      icon: InitiativesIcon,
      end: false,
    },
    {
      name: capitalize(i18n.t('actions.action_other')),
      to: objectiveRoutesPaths.actions.root({
        params: {
          teamSlug: teamAdapter.toParam(),
          objectiveId,
        },
      }),
      icon: ActionIcon,
      end: true,
    },
  ].filter(Boolean);
};
