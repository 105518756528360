import type { PropsWithChildren, ReactNode } from 'react';
import {
  Tooltip as AriaTooltip,
  Button,
  OverlayArrow,
  TooltipTrigger,
} from 'react-aria-components';
import styled from '@emotion/styled';
import type { Placement } from 'react-aria';
import hexToRgba from 'hex-to-rgba';
import { css } from '@emotion/react';

const StyledAriaButton = styled(Button)`
  all: unset;
  display: flex;
  align-items: center;
`;

const StyledAriaTooltip = styled(AriaTooltip)`
  background: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.strokeMedium};
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0 0 0.75rem ${(props) => hexToRgba(props.theme.color.black, 0.1)};
  max-width: 350px;

  &[data-placement='top'] {
    margin-bottom: 8px;
    --origin: translateY(4px);
  }

  &[data-placement='bottom'] {
    margin-top: 8px;
    --origin: translateY(-4px);
    & .react-aria-OverlayArrow svg {
      transform: rotate(180deg);
    }
  }

  &[data-placement='right'] {
    margin-left: 8px;
    --origin: translateX(-4px);
    & .react-aria-OverlayArrow svg {
      transform: rotate(90deg);
    }
  }

  &[data-placement='left'] {
    margin-right: 8px;
    --origin: translateX(4px);
    & .react-aria-OverlayArrow svg {
      transform: rotate(-90deg);
    }
  }

  & .react-aria-OverlayArrow svg {
    display: block;
    fill: ${(props) => props.theme.color.strokeMedium};
  }
`;

type Props = PropsWithChildren<{
  className?: string;
  content?: ReactNode;
  delay?: number;
  isInline?: boolean;
  isOpen?: boolean;
  placement?: Placement;
}>;

const Tooltip = ({
  children,
  content,
  delay = 1000,
  placement = 'top',
  className,
  isInline = false,
  isOpen,
}: Props) => (
  <TooltipTrigger delay={delay} isOpen={isOpen}>
    <StyledAriaButton
      className={className}
      css={isInline && css({ display: 'inline-block' })}
    >
      {children}
    </StyledAriaButton>
    {content && (
      <StyledAriaTooltip placement={placement}>
        {content}

        <OverlayArrow>
          <svg width={8} height={8}>
            <path d={'M0 0,L4 4,L8 0'} />
          </svg>
        </OverlayArrow>
      </StyledAriaTooltip>
    )}
  </TooltipTrigger>
);

export default Tooltip;
