import type { Entries } from 'type-fest';

import type { InitiativeStatusWizardStatusProps } from 'initiative/InitiativeStatusWizard/InitiativeStatusWizardStatus/InitiativeStatusWizardStatus';
import { yup } from 'shared/services/yup.service';
import { StatusIndicator } from 'shared/status/StatusIndicator';
import type { InitiativeStatusWizardKeyIndicatorsProps } from 'initiative/InitiativeStatusWizard/InitiativeStatusWizardKeyIndicators';
import type {
  Initiative,
  InitiativeStatusInput,
  MilestoneStatusInput,
} from 'types.graphql.generated';
import type { MilestoneStatusFormSubmitValues } from 'initiative/MilestoneStatusForm';
import { date } from 'shared/services/date.service';
import type { TeamAdapter } from 'team/TeamAdapter';

import type { InitiativeStatusWizardValues } from './InitiativeStatusWizard.type';
import type { InitiativeStatusForStatusWizard } from './InitiativeStatusWizard';
import type { InitiativeStatusWizardInitiativeFragment } from './InitiativeStatusWizard.graphql.generated';

export const resolveStatusKeyIndicators = (params: {
  initiative: InitiativeStatusWizardInitiativeFragment;
  values: InitiativeStatusWizardValues;
}): NonNullable<InitiativeStatusWizardStatusProps['keyIndicators']> => {
  const {
    values,
    initiative: { currentInitiativeStatus },
  } = params;
  return {
    keyIndicatorOnBudget: yup
      .boolean()
      .cast(
        values.keyIndicators?.onBudget ||
          currentInitiativeStatus?.keyIndicatorOnBudget ||
          false,
      ),
    keyIndicatorOnOutcome: yup
      .boolean()
      .cast(
        values.keyIndicators?.onOutcome ||
          currentInitiativeStatus?.keyIndicatorOnOutcome ||
          false,
      ),
    keyIndicatorOnTime: yup
      .boolean()
      .cast(
        values.keyIndicators?.onTime ||
          currentInitiativeStatus?.keyIndicatorOnTime ||
          false,
      ),
  };
};

export const resolveStatusMilestonesStatusIndicators = (params: {
  values: InitiativeStatusWizardValues;
}): InitiativeStatusWizardStatusProps['milestonesStatusIndicators'] => {
  const { values } = params;
  return values.milestones
    ? (
        Object.values(values.milestones).filter(
          Boolean,
        ) as MilestoneStatusFormSubmitValues[]
      ).map(
        ({ statusIndicator, complete }) =>
          new StatusIndicator(statusIndicator, { isCompleted: complete }),
      )
    : undefined;
};

export const resolveKeyIndicatorsInitialValues = (params: {
  initiative: InitiativeStatusWizardInitiativeFragment;
  values: InitiativeStatusWizardValues;
}): NonNullable<InitiativeStatusWizardKeyIndicatorsProps['initialValues']> => {
  const {
    values,
    initiative: { currentInitiativeStatus },
  } = params;
  if (values.keyIndicators) {
    return values.keyIndicators;
  }
  return {
    onBudget:
      currentInitiativeStatus?.keyIndicatorOnBudget != null
        ? String(currentInitiativeStatus?.keyIndicatorOnBudget)
        : null,
    onTime:
      currentInitiativeStatus?.keyIndicatorOnTime != null
        ? String(currentInitiativeStatus?.keyIndicatorOnTime)
        : null,
    onOutcome:
      currentInitiativeStatus?.keyIndicatorOnOutcome != null
        ? String(currentInitiativeStatus?.keyIndicatorOnOutcome)
        : null,
  };
};

export const resolveAddInitiativeStatusInput = (params: {
  initiative: Pick<Initiative, 'id'>;
  status?: InitiativeStatusForStatusWizard;
  teamAdapter: TeamAdapter;
  values: InitiativeStatusWizardValues;
}): InitiativeStatusInput => {
  const { status, values, initiative, teamAdapter } = params;

  return {
    idToUpdate: status?.id,
    orgKey: teamAdapter.orgKey,
    initiativeId: initiative.id,
    milestoneStatuses: {
      add: (
        Object.entries(values.milestones || []) as Entries<
          typeof values.milestones
        >
      )
        .map(([milestoneId, milestoneData]) => {
          if (milestoneData) {
            return {
              milestoneId,
              statusIndicator: milestoneData.statusIndicator,
              comment: milestoneData.comment,
              completionRate: milestoneData.completionRate,
            };
          }
          return undefined;
        })
        .filter(Boolean) as MilestoneStatusInput[],
      idsToDelete: status?.milestoneStatuses.map(
        (milestoneStatus) => milestoneStatus.id,
      ),
    },
    keyIndicatorOnBudget: yup
      .boolean()
      .cast(values.keyIndicators?.onBudget || false),
    keyIndicatorOnOutcome: yup
      .boolean()
      .cast(values.keyIndicators?.onOutcome || false),
    keyIndicatorOnTime: yup
      .boolean()
      .cast(values.keyIndicators?.onTime || false),
    successComments: {
      add: values.comments?.successes.map((text) => ({ text })),
      idsToDelete: status?.successComments.map((comment) => comment.id),
    },
    challengeComments: {
      add: values.comments?.challenges.map((text) => ({ text })),
      idsToDelete: status?.challengeComments.map((comment) => comment.id),
    },
    actionComments: {
      add: values.comments?.actions.map((text) => ({ text })),
      idsToDelete: status?.actionComments.map((comment) => comment.id),
    },
    statusIndicator: values.status?.statusIndicator || undefined,
    comment: values.status?.comment,
    complete: values.status?.complete,
    statusDateTime: values.status?.date
      ? date.formatRfc3339(values.status?.date)
      : undefined,
  };
};
