import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import { css } from '@emotion/react';

import LayoutHeaderContainer from 'shared/components/Layout/LayoutHeaderContainer';
import type { LinkItem } from 'shared/components/Layout/LayoutHeaderTopSection';
import LayoutHeaderTopSection from 'shared/components/Layout/LayoutHeaderTopSection';
import LayoutHeaderHeading from 'shared/components/Layout/LayoutHeaderHeading';
import { date } from 'shared/services/date.service';
import LabeledHeadingText from 'shared/components/LabeledHeadingText';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import StatusTag from 'shared/status/StatusTag';
import UserAvatar from 'user/UserAvatar/UserAvatar';
import LinkTabs from 'shared/components/LinkTabs';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import { useMyTeamRoutePath } from 'team/useMyTeamRoutePath';
import Flex from 'shared/components/Flex';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import InitiativeContextMenu from 'initiative/InitiativeContextMenu/InitiativeContextMenu';
import TeamLink from 'team/TeamLink';
import TeamImage from 'team/TeamImage';

import styles from './InitiativeOverviewLayoutHeader.module.scss';
import {
  getBreadcrumbsItems,
  getNavigationItems,
} from './InitiativeOverviewLayoutHeader.utils';
import { useInitiativeOverview } from '../InitiativeOverviewProvider';
import { useOrgUnit } from '../../../orgUnit/OrgUnitProvider';

const InitiativeLayoutHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { teamAdapter } = useTeamAdapter();
  const { initiative } = useInitiativeOverview();
  const { priorities } = useStrategyProfilePriorities();
  const { objectiveId, initiativeId } = useParams() as {
    initiativeId: string;
    objectiveId: string;
  };

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();

  const { routePath: myTeamRoutePath, isMatch: isMyTeamRoutePathMatch } =
    useMyTeamRoutePath();

  const topRightNavItem: Maybe<LinkItem> = !isMyTeamRoutePathMatch
    ? {
        name: t('team.layout.goToTeam'),
        to: myTeamRoutePath,
      }
    : undefined;

  const dates = useMemo(() => {
    const startDate = initiative?.timeLine.startDate
      ? date.format(initiative?.timeLine.startDate, 'dd MMM yyyy')
      : t('notSet');
    const endDate = initiative?.timeLine.endDate
      ? date.format(initiative?.timeLine.endDate, 'dd MMM yyyy')
      : t('notSet');

    return `${startDate} - ${endDate}`;
  }, [initiative, t]);

  const breadcrumbsItems = getBreadcrumbsItems({
    t,
    teamAdapter,
    objectiveId,
    initiativeId,
    orgUnit,
    activeOrg,
  });

  const navigationItems = useMemo<LinkItem[]>(() => getNavigationItems(), []);

  return (
    <LayoutHeaderContainer>
      <div className={styles.content}>
        <LayoutHeaderTopSection
          breadcrumbs={breadcrumbsItems}
          topRightLinkItem={topRightNavItem}
          className={styles.topSection}
        />
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          css={css({ marginBottom: 12 })}
          gap={12}
        >
          {initiative && (
            <TeamLink orgUnit={initiative.orgUnit}>
              <TeamImage orgUnit={initiative.orgUnit} size={'small'} />
            </TeamLink>
          )}
          <LayoutHeaderHeading
            content={
              <LabeledHeadingText
                label={capitalize(t('initiative.initiative'))}
                textContent={initiative?.name}
              />
            }
            isBold={false}
          />
          <div className={styles.headingExtra}>
            <StatusTag
              statusIndicator={
                initiative?.currentInitiativeStatus?.statusIndicator
              }
            />
            <UserAvatar user={initiative?.owner} />
            {initiative && (
              <InitiativeContextMenu
                initiative={initiative}
                onDelete={() =>
                  navigate(
                    objectiveRoutesPaths.root({
                      params: { teamSlug: teamAdapter.toParam(), objectiveId },
                    }),
                  )
                }
              />
            )}
          </div>
        </Flex>
        <Flex gap={20}>
          {dates && <p className={styles.description}>{dates}</p>}
          {(priorities || initiative?.priority) && (
            <p className={styles.description}>
              {t('priority.priority')}
              {': '}
              {initiative?.priority?.label || t('priority.noPriority')}
            </p>
          )}
        </Flex>
        <LinkTabs items={navigationItems} />
      </div>
    </LayoutHeaderContainer>
  );
};

export default InitiativeLayoutHeader;
