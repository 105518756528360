import UserAvatar from 'user/UserAvatar';
import { useUser } from 'user/UserProvider';

import ChatBubble from './ChatBubble';
import type { InputMessage } from './AskSophieSidePanel';
import { Spacer } from './SummaryMessageItem';

type Props = {
  message: InputMessage;
};

const InputMessageItem = ({ message }: Props) => {
  const { user } = useUser();

  return (
    <>
      <Spacer />
      <ChatBubble messageType={'InputMessage'}>{message.content}</ChatBubble>
      <UserAvatar user={user} />
    </>
  );
};

export default InputMessageItem;
